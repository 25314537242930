/**
 * formatMessage format method
 * @param {object} locale, getMessage, and interpolator from the context
 * @param {string} messageId The message id to find in the dictionary
 * @param {object} values key value pairs with properties to replace the tokens
 * @param {string} defaultMessage The templated string to be formatted
 * @param {function} customInterpolator a custom interpolator function to parse the strings
 * @param {string} description adds context to the localized string
 * @return {string} formatted and localized string
 */
export function formatMessage(
  { locale, getMessage, interpolator },
  {
    messageId = '',
    values,
    defaultMessage = 'no default message string was provided',
    customInterpolator = false
  }
) {
  if (!getMessage) {
    return defaultMessage;
  }

  const messageValue = getMessage(messageId) || defaultMessage;

  if (!customInterpolator && typeof customInterpolator !== 'function') {
    return interpolator(messageValue, values, locale);
  }
  return customInterpolator(messageValue, values, locale);
}
