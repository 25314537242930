import memoizeIntlConstructor from 'intl-format-cache';
import IntlRelativeFormat from 'intl-relativeformat';

/**
 * FormatRelative format method
 * @param  {number} value Number to be formatted
 * @param  {number} now Date timestamp representing "now"
 * @param  {object} options Options
 * @return {string} formatted number as a string
 */
export function formatRelative({ locale }, { value, now, options }) {
  const newNow = isFinite(now) ? new Date(now) : Date.now();
  if (!options) {
    options = {
      style: 'best fit',
      units: 'day'
    }
  }
  try {
    const formatter = memoizeIntlConstructor(IntlRelativeFormat);

    return formatter(locale, options).format(value, {
      now: newNow
    });
  } catch (error) {
    console.error(
      `Failed to format relative date.\n${error}` +
        `For documentaion on options, please refer to:` +
        `https://www.npmjs.com/package/intl-relativeformat`
    );
  }
  return String(new Date(value));
}
