import IntlMessageFormat from 'intl-messageformat';
import memoizeIntlConstructor from 'intl-format-cache';

export const formatMessageInterpolator = (message, values, locale, formats) => {
  let formattedMessage;

  try {
    const formatter = memoizeIntlConstructor(IntlMessageFormat);

    formattedMessage = formatter(message, locale, formats).format(values);
  } catch (error) {
    console.error(
      `Failed to format message: "${message}" of locale: "${locale}"\n${error}`
    );
  }

  return formattedMessage || message;
};
