import memoizeIntlConstructor from 'intl-format-cache';

/**
 * formatNumber format method
 * @param  {number} value Number to be formatted
 * @param  {object} options Options
 * @return {string} formatted number as a string
 */
export function formatNumber({ locale }, { value, options }) {
  try {
    const formatter = memoizeIntlConstructor(Intl.NumberFormat);

    return formatter(locale, options).format(value);
  } catch (error) {
    console.error(
      `Failed to format number.\n${error}` +
        `For documentaion on options, please refer to:` +
        `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat`
    );
  }
  return String(value);
}
