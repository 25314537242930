import PropTypes from 'prop-types';
import { I18nContext } from '../i18n';
import React, { Component } from 'react';
import { formatDateTime } from './formatDateTime';
/**
 * FormattedDateTime functional formatter component
 * @param {object} provider the reference to the provider for i18n
 * @param  {number} value Date timestamp
 * @param  {object} options Options
 * @return {string} formatted number as a string
 * @constructor
 */
class FormattedDateTime extends Component {
  static displayName = 'i18nFormattedDateTime';

  static defaultProps = {
    options: {}
  };

  static propTypes = {
    /** The number value to format. */
    value: PropTypes.number.isRequired,
    /** Options passed in */
    options: PropTypes.objectOf(PropTypes.shape({}))
  };

  render() {
    return (
      <I18nContext.Consumer>
        {context => {
          const { value, options } = this.props;

          return formatDateTime(context, {
            value,
            options
          });
        }}
      </I18nContext.Consumer>
    );
  }
}

export default FormattedDateTime;
