import React, { Component } from 'react';
import { I18nContext } from '../i18n';
import PropTypes from 'prop-types';
import { formatNumber } from './formatNumber';

/**
 * FormattedNumber functional formatter component
 * @param  {number} value Number to be formatted
 * @param  {object} options Options
 * @return {string} formatted number as a string
 * @constructor
 */
class FormattedNumber extends Component {
  static displayName = 'i18nFormattedNumber';

  static defaultProps = {
    options: {}
  };

  static propTypes = {
    /** The number value to format. */
    value: PropTypes.number.isRequired,
    /** Options passed in */
    options: PropTypes.objectOf(PropTypes.shape({}))
  };

  render() {
    return (
      <I18nContext.Consumer>
        {context => {
          const { value, options } = this.props;

          return formatNumber(context, {
            value,
            options
          });
        }}
      </I18nContext.Consumer>
    );
  }
}

export default FormattedNumber;
