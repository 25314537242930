import React, { Component } from 'react';
import { I18nContext } from '../i18n';
import PropTypes from 'prop-types';
import { formatMessage } from './formatMessage';

/**
 * FormattedMessage functional formatter component
 * @param {object} provider the reference to the provider for i18n
 * @param {string} messageId The message id to find in the dictionary
 * @param {object} values key value pairs with properties to replace the tokens
 * @param {string} defaultMessage The templated string to be formatted
 * @param {function} customInterpolator a custom interpolator function to parse the strings
 * @param {string} description adds context to the localized string
 * @return {string} formatted and localized string
 * @constructor
 */
class FormattedMessage extends Component {
  static displayName = 'i18nFormattedMessage';

  static defaultProps = {
    defaultMessage: 'no default message string was provided',
    messageId: '',
    values: {},
    customInterpolator: false,
    description: ''
  };

  static propTypes = {
    /** The default message to be formatted */
    defaultMessage: PropTypes.string,
    /** The id corresponding to the message to be formatted */
    messageId: PropTypes.string,
    /** Object of key, value pairs */
    values: PropTypes.objectOf(PropTypes.shape({})),
    /** Optional custom interpolator */
    customInterpolator: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /** description to add context to the localized string */
    description: PropTypes.string
  };

  render() {
    return (
      <I18nContext.Consumer>
        {context => {
          const {
            messageId,
            values,
            defaultMessage,
            customInterpolator,
            description
          } = this.props;

          return formatMessage(context, {
            messageId,
            values,
            defaultMessage,
            customInterpolator,
            description
          });
        }}
      </I18nContext.Consumer>
    );
  }
}

export default FormattedMessage;
