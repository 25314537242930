import React from 'react';
import PropTypes from 'prop-types';

/**
 * Error handler component for issues in the Adobe Intl React library
 */
class I18nErrors extends React.Component {
  static propTypes = {
    /** The name of the component that is being lcoalized with possible errors */
    displayName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    const possibleMissingContext = /(undefined|evaluating|context)/g;
    const errorString = error.toString();
    const trimErrorInfo = info =>
      info.componentStack
        .split('\n')
        .filter(Boolean)
        .map(line => line.trim());
    let errorMessage =
      'i18nError, please check logs filtered by AdobeIntlReact';

    if (errorString.match(possibleMissingContext)) {
      errorMessage =
        'component likely failed because it is missing an i18nProvider, please check logs filtered by AdobeIntlReact';
    }

    this.setState({
      hasError: true,
      error: errorString,
      errorInfo: trimErrorInfo(errorInfo),
      errorMessage: errorMessage
    });
  }
  render() {
    const { children, displayName } = this.props;
    const { hasError, error, errorMessage, errorInfo } = this.state;

    if (hasError) {
      console.error('AdobeIntlReact - ERROR', error);
      console.error('AdobeIntlReact - STACK', errorInfo);
      return `${displayName} - ${errorMessage}`;
    }
    return children;
  }
}

export { I18nErrors as default };
