import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { I18nContext } from '../i18n';
import { formatRelative } from './formatRelative';

/**
 * FormattedRelative functional formatter component
 * @param  {number} value Number to be formatted
 * @param  {number} now Date timestamp representing "now"
 * @param  {object} options Options
 * @return {string} formatted number as a string
 * @constructor
 */
class FormattedRelative extends Component {
  static displayName = 'i18nFormattedRelative';

  static defaultProps = {
    options: {
      style: 'best fit',
      units: 'day'
    }
  };

  static propTypes = {
    /** The numrical value to format. */
    value: PropTypes.number.isRequired,
    /** now Date timestamp representing "now" */
    now: PropTypes.number,
    /** OneOf(['best fit', 'numeric']) */
    options: PropTypes.objectOf(PropTypes.shape({}))
  };

  render() {
    return (
      <I18nContext.Consumer>
        {context => {
          const { value, now, options } = this.props;

          return formatRelative(context, {
            value,
            now,
            options
          });
        }}
      </I18nContext.Consumer>
    );
  }
}

export default FormattedRelative;
