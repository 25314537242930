/*
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import ExcCore, {CoreContext, Message} from '@exc/core';
import React from 'react';
import {Router, Route} from '@exc/router';

let ReactModule = React.lazy(() => import('@exc-modules/react-routes'));

// config options can include overrides for default locale, default org, IMS settings, etc
// see /typings/configuration.ts for the shape of this object.
// let config = {};
let config = {
  environment: 'prod',
  ims: {
    client_id: 'campaign_mcbrs_webapp',
    scope:
      'AdobeID,openid,read_organizations,creative_cloud,additional_info.job_function,additional_info.projectedProductContext,additional_info.roles,session'
    // "redirect_uri": "https://bcc-reporting-dev.s3.amazonaws.com/"
    // "redirect_uri": "https://loh.corp.adobe.com:1234/"
  }
};

export default function BccReportingApp() {
  // Setting loginRedirectDisabled to true would stop redirection to landing page preference of user post login
  // Currently the loginRedirectDisabled is set to true by default but in future default behavior would be false

  //<ExcCore configuration={config} loginRedirectDisabled={true} > - redirectDisabled true
  //<ExcCore configuration={config} loginRedirectDisabled={false} > - redirectDisabled false
  //<ExcCore configuration={config} > - redirectDisabled true
  return (
    <ExcCore configuration={config} toastPlacement="bottom center">
      <Router>
        <Route path="/campaign" title="BCC Summary">
          <CoreContext.Consumer>
            {({adobeIMS, features, imsOrg}) => <ReactModule adobeIMS={adobeIMS} features={features} imsOrg={imsOrg} />}
          </CoreContext.Consumer>
        </Route>
        <Route path="/" redirect="/campaign" />
        <Route>
          <Message message="noAccess" />
        </Route>
      </Router>
    </ExcCore>
  );
}
