import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IntlRelativeFormat from 'intl-relativeformat';
import { formatMessageInterpolator } from './interpolator/FormatMessageInterpolator';
import * as format from './format';
import { formatFunctionPropTypes } from './types';
/** this isn't working yet and may not work with Parcel */
//import * as locales from 'intl-relativeformat/dist/locale-data/*.js';

const supportedLocales = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'zh'];
const defineGlobalIntl = () => {
  if (typeof window !== 'undefined') {
    window.IntlRelativeFormat = IntlRelativeFormat;
  } else if (typeof global !== 'undefined') {
    global.IntlRelativeFormat = IntlRelativeFormat;
  }
};

const formatFunctionNames = Object.keys(formatFunctionPropTypes);

const I18nContext = React.createContext();

/**
 * I18n Provider
 */
class I18nProvider extends Component {
  static defaultProps = {
    locale: 'en-US',
    localeData: {},
    autoLocale: false,
    interpolator: formatMessageInterpolator
  };

  static propTypes = {
    /** User language locale string */
    locale: PropTypes.string,
    /** Language strings data */
    localeData: PropTypes.objectOf(PropTypes.shape({})),
    /** Automatically switch the locale based on the user browser preferences */
    autoLocale: PropTypes.bool,
    /** interpolator function to parse string messages */
    interpolator: PropTypes.func
  };

  constructor(props) {
    super(props);
    let { locale, localeData } = this.props;
    const { autoLocale, interpolator } = this.props;

    if (autoLocale === true) {
      locale = this.getUserLanguage();
    }

    defineGlobalIntl();
    this.getMessage = this.getMessage.bind(this);
    this.getUpdatedLocaleData = this.getUpdatedLocaleData.bind(this);
    this.getLocaleDataWithLanguageCode = this.getLocaleDataWithLanguageCode.bind(
      this
    );
    localeData = this.getUpdatedLocaleData(localeData);

    this.state = {
      locale,
      localeData,
      interpolator
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let { locale, localeData, interpolator } = this.props;
    const { autoLocale } = this.props;
    let currentLocaleData = prevState.localeData;

    if (autoLocale === true) {
      locale = this.getUserLanguage();
    }
    if (localeData && localeData !== prevProps.localeData) {
      currentLocaleData = this.getUpdatedLocaleData(localeData);
    }
    if (
      locale !== prevState.locale ||
      currentLocaleData !== prevState.localeData ||
      interpolator !== prevState.interpolator
    ) {
      this.setState({
        locale: locale,
        localeData: currentLocaleData,
        interpolator: interpolator
      });
    }
  }

  /**
   * Method to replace the '_' in locale with '-'
   * @param {object} localeData the original localeData with '_' in locales
   * @returns {object} The updated localeData with '-' in locales
   */
  getUpdatedLocaleData(localeData) {
    const updatedLocaleData = {};

    Object.keys(localeData).forEach(local => {
      const localeKey = local.replace(/_/g, '-');

      updatedLocaleData[localeKey] = localeData[local];
    });

    return updatedLocaleData;
  }

  /**
   * Method to retrieve the locale data given the supported locale instead of full locale
   * @param {string} supportedLocale Input supported locale
   * @returns {object} The messages corresponding to the supportedLocale
   */
  getLocaleDataWithLanguageCode(supportedLocale) {
    const { localeData } = this.state;
    let messages;

    Object.keys(localeData).forEach(local => {
      if (local.startsWith(supportedLocale)) {
        messages = localeData[local];
      }
    });

    return messages;
  }

  /**
   * Method to retrieve the templated string from the localeData
   * @param {string} messageId The message id to retrieve the templated string
   * @returns {string} The templated string if found otherwise and empty string
   */
  getMessage(messageId) {
    const { locale, localeData } = this.state;
    let messages;

    if (typeof localeData[locale] === 'object') {
      messages = localeData[locale];
    } else if (supportedLocales.includes(locale)) {
      messages = this.getLocaleDataWithLanguageCode(locale);
    }

    if (messages && messages[messageId]) {
      return messages[messageId];
    }

    return null;
  }

  /**
   * Method to get the user navigator language locale
   * @returns {string} the string locale
   */
  getUserLanguage() {
    return window.navigator.userLanguage || window.navigator.language;
  }

  /**
   * Method to get the user navigator language locale
   * @param {string} locale the string for functions
   * @returns {string} the string locale
   */
  normalizeLocale(locale) {
    return locale.replace(/_/g, '-');
  }

  /**
   * Method to bind each format method to context values
   * @param {object} contextValues for functions
   * @returns {object} of bound functions
   */
  getBoundFormatFns(contextValues) {
    return formatFunctionNames.reduce((boundFormatFns, name) => {
      boundFormatFns[name] = format[name].bind(null, contextValues);
      return boundFormatFns;
    }, {});
  }

  render() {
    const { children } = this.props;
    const { interpolator, locale, localeData } = this.state;
    const getMessage = this.getMessage;
    const boundFormatFns = this.getBoundFormatFns({
      locale,
      interpolator,
      getMessage
    });

    return (
      <I18nContext.Provider
        value={{
          interpolator: interpolator,
          getUserLanguage: this.getUserLanguage,
          getMessage: getMessage,
          locale: locale,
          localeData: localeData,
          ...boundFormatFns
        }}
      >
        {children}
      </I18nContext.Provider>
    );
  }
}

const I18nConsumer = I18nContext.Consumer;

export { I18nProvider as default, I18nConsumer, I18nContext };
