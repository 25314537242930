import memoizeIntlConstructor from 'intl-format-cache';

/**
 * formatDateTime format method
 * @param {object} provider the reference to the provider for i18n
 * @param  {number} value Date timestamp
 * @param  {object} options Options
 * @return {string} formatted number as a string
 */
export function formatDateTime({ locale }, { value, options }) {
  const date = new Date(value);

  try {
    const formatter = memoizeIntlConstructor(Intl.DateTimeFormat);

    return formatter(locale, options).format(date);
  } catch (error) {
    console.error(
      `Failed to format date or time.\n${error}` +
        `For options, please refer to:` +
        `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat`
    );
  }
  return String(value);
}
