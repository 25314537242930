import React from 'react';
import { I18nConsumer } from './i18n';
import I18nErrors from './i18nErrors';

/**
 * HOC to add i18n Consumer easily
 * @param {node} Component React component to be enhanced
 * @returns {LocalizedComponent} the React component with the added localization context
 */
export function withLocalization(Component) {
  return function LocalizedComponent(props) {
    return (
      <I18nErrors displayName={Component.name}>
        <I18nConsumer>
          {context => (
            <Component
              locale={context.locale}
              localeData={context.localeData}
              {...props}
              interpolator={context.interpolator}
              getUserLanguage={context.getUserLanguage}
              getMessage={context.getMessage}
              formatMessage={context.formatMessage}
              formatHTMLMessage={context.formatHTMLMessage}
              formatNumber={context.formatNumber}
              formatDateTime={context.formatDateTime}
              formatRelative={context.formatRelative}
            />
          )}
        </I18nConsumer>
      </I18nErrors>
    );
  };
}
